import { For, Portal } from "solid-js/web";
import { ModalEvents, ModalViewProps } from "../model";
import { $modals } from "../store";
import { Match, Show, Switch, splitProps } from "solid-js";
import { Atom, state } from ":mods";

// TODO: add drawer to display other modals and allowjumping between them on click
// and allow linking dependant modals with modal tree like structure. also the for loop
// is going to be for the drawer and only one modal at a time is gonna be active
export function Modal() {
  const clickOutsideEvent$ = Atom.Directive.method.clickOutsideEvent$;
  return (
    <Portal>
      <Show when={$modals.value.length > 0}>
        <main class={`!absolute top-0 !z-99 flex flex-row items-center justify-center w-full h-full bg-black/30`}>
          <For each={$modals.value}>
            {(props, index) => {
              const [local, other] = splitProps(props, ["title", "element", "startExpanded"]);
              local.element =
                typeof local.element === "function"
                  ? local.element((event) => onModalAction(event, index()))
                  : local.element;
              const $expand = state.create(local.startExpanded ?? false);
              function onModalAction(event: ModalEvents, idx: number) {
                if (event === "close") {
                  $modals.set((s) => s.filter((_, i) => i !== idx));
                } else if (event === "expand" && !$expand.value) {
                  $expand.set(true);
                } else if (event === "shrink" && $expand.value) {
                  $expand.set(false);
                }
              }
              return (
                //? outer container
                <section
                  class={`!z-10 !my-1
                  px-2 py-3
                  flex flex-col  self-center !items-center !justify-center  transition-all duration-300 ease-in-out
                  ${$expand.value ? " w-full h-full " : " w-70% h-70% "}
                `}
                  use:clickOutsideEvent$={() => {
                    onModalAction("close", index());
                  }}
                >
                  {/* inner container */}
                  <section
                    {...other.container}
                    class={`
                    !z-50 flex flex-col w-full h-full justify-between !self-center overflow-hidden rounded-lg 
                    bg#paper text#p
                    px-2 py-1
                    ${other.container?.class ?? ""}
                    `}
                  >
                    <div class="!sticky !top-0 flex flex-row justify-between items-center border-b border-solid border#p bg#paper bg#p">
                      <h1>{local.title || "TITLE"}</h1>
                      <div class="flex flex-row items-center space-x-2">
                        <Switch>
                          <Match when={$expand.value}>
                            <i
                              class="icon-lucide:shrink w-30px h-30px cursor-pointer"
                              onclick={() => onModalAction("shrink", index())}
                            />
                          </Match>
                          <Match when={!$expand.value}>
                            <i
                              class="icon-bx:expand w-30px h-30px cursor-pointer"
                              onclick={() => onModalAction("expand", index())}
                            />
                          </Match>
                        </Switch>
                        <i
                          class="icon-carbon:close-filled w-30px h-30px cursor-pointer"
                          onclick={() => onModalAction("close", index())}
                        />
                      </div>
                    </div>
                    <div class="flex flex-col w-full h-full overflow-auto">{local.element}</div>
                  </section>
                </section>
              );
            }}
          </For>
        </main>
      </Show>
    </Portal>
  );
}
